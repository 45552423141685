<template>
  <router-view></router-view>
</template>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routeGuardAdmin(to, from, next)
    })
  },

  beforeRouteUpdate(to, from, next) {
    next(vm => {
      vm.routeGuardAdmin(to, from, next)
    })
  },
  methods: {
    routeGuardAdmin(to, from, next) {
      console.log("Admin Guard")
      console.log(to)
      const loggedIn = this.$store.state.users.isLoggedIn
      if (loggedIn) {
        if (!this.$store.state.users.isAdmin) {
          next("/dashboard")
        } else {
          next()
        }
      } else if (loggedIn === false) {
        console.info("Redirect to login")
        next("/login")
      }
    },
  },
}
</script>
